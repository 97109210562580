import React from "react"
import Tile from "./tile"

interface TileOverviewProps {
  tileDate: any
}

const TileOverview: React.FC<TileOverviewProps> = props => {
  return (
    <div>
      <div className="tile__grid--container">
        <div className="tile__grid tile-slider">
          {props.tileDate.map((item, index) => (
            <Tile
              key={index}
              title={item.content.general.title}
              description={item.content.general.description}
              image={item.content.general.image}
              buttonText={item.content.general.buttonText}
              path={item.content.general.path}
              isFirst={index === 0}
            ></Tile>
          ))}
        </div>
      </div>
    </div>
  )
}

export default TileOverview
